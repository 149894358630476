import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './PageIndicator.scss';
import { setContentScrolltop } from '../../services/util-service/util.service';

const PageIndicator = (props) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(props.page || 1);
  const [visiblePageSegmentCount, setVisiblePageSegmentCount] = useState(5);

  useEffect(() => {
    setVisiblePageSegmentCount(window.innerWidth < 500 ? 3 : 5);
  }, []);

  useEffect(() => {
    const { onPageChange, page, preventScrollTop, scrollIntoView } = props;
    if (page !== currentPage) {
      if (onPageChange) onPageChange(currentPage);
      if (!preventScrollTop) {
        setContentScrolltop(scrollIntoView);
      }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const goNext = () => {
    const { page, pageCount } = props;
    if (page !== pageCount) {
      setCurrentPage(page + 1);
    }
  };

  const goPrevious = () => {
    const { page } = props;
    if (page > 1) {
      setCurrentPage(page - 1);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const movePageIndex = (addition) => {
    const { page, pageCount } = props;
    let newCurrentPage = page + addition;
    if (newCurrentPage <= 1) {
      newCurrentPage = 1;
    } else if (newCurrentPage >= pageCount) {
      newCurrentPage = pageCount;
    }
    setCurrentPage(newCurrentPage);
  };

  const renderFirstPageIndicator = () => {
    const { page, pageCount } = props;
    let view = null;
    if (pageCount > 9 && page > visiblePageSegmentCount - 1) {
      view = renderNumber(1);
    }
    return view;
  };

  const renderLastPageIndicator = () => {
    const { page, pageCount } = props;
    let view = null;
    if (pageCount > 9 && page + 3 <= pageCount) {
      view = renderNumber(pageCount);
    }
    return view;
  };

  const renderLeftSpread = () => {
    const { page: currentPage, pageCount } = props;
    return pageCount > 9 && currentPage > visiblePageSegmentCount - 1 ? getSpread() : null;
  };

  const renderNext = () => {
    const { page, pageCount } = props;
    const classes = ['Next'];
    if (page === pageCount) {
      classes.push('disabled');
    }
    return (
      <div className={classes.join(' ')} onClick={goNext}>
        {t('PageIndicator.next')}
      </div>
    );
  };

  const renderNumber = (number) => {
    const { page } = props;
    const classes = ['Number'];
    const isCurrentPage = page === number;
    if (isCurrentPage) {
      classes.push('Active');
      classes.push('disabled');
    }
    const additionalData = isCurrentPage
      ? {}
      : {
          onClick: goToPage.bind(this, number),
        };

    return (
      <div className={classes.join(' ')} {...additionalData} key={number}>
        {number}
      </div>
    );
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const { page, pageCount } = props;
    let start = 0;
    let end = pageCount;
    if (pageCount > 9) {
      start = 0;
      end = visiblePageSegmentCount;
      if (page > visiblePageSegmentCount - 1) {
        const half = Math.floor(visiblePageSegmentCount / 2);
        start = page - (half + 1);
        end = page + half;
        if (end > pageCount) {
          end = pageCount;
          start = end - visiblePageSegmentCount;
        }
      }
    }
    for (let i = start; i < end; i++) {
      pageNumbers.push(i + 1);
    }
    return (
      <div className="Numbers">
        {renderFirstPageIndicator()}
        {renderLeftSpread()}
        {pageNumbers.map(renderNumber)}
        {renderRightSpread()}
        {renderLastPageIndicator()}
      </div>
    );
  };

  const renderPrevious = () => {
    const { page } = props;
    const classes = ['Previous'];
    if (page === 1) {
      classes.push('disabled');
    }
    return (
      <div className={classes.join(' ')} onClick={goPrevious}>
        {t('PageIndicator.previous')}
      </div>
    );
  };

  const getSpread = (isRight = false) => (
    <div className="Spread">
      <i className="fas fa-ellipsis-h" />
      <i
        className={`fas fa-angle-double-${isRight ? 'right' : 'left'}`}
        onClick={movePageIndex.bind(this, isRight ? visiblePageSegmentCount : -visiblePageSegmentCount)}
      />
    </div>
  );

  const renderRightSpread = () => {
    const { page, pageCount } = props;
    return pageCount > 9 && page < pageCount - 2 ? getSpread(true) : null;
  };

  return (
    <div className="PageIndicator">
      {renderPrevious()}
      {renderPageNumbers()}
      {renderNext()}
    </div>
  );
};

PageIndicator.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageCount: PropTypes.number,
};

export default PageIndicator;
