import React, { useRef } from 'react';

import { getStudioDetailsRoute } from '../../../../services/navigation/navigation.service.routes';
import useInViewport from '../../../../hook/useInViewport';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';

import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';

const StudioDetailsPageSceneSiteSpecific = (props) => {
  const { studioId, studioName, viewType, location = {}, numberOfItems, title, hidePoster } = props;

  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, sort, type, loading, error, onTypeChange, onSortByClick } = useMoviesScenesData({
    location,
    isVisible,
    videosType: viewType,
    hookParams: { studios_id: studioId },
  });

  const slicedVideos = numberOfItems ? videos.slice(0, numberOfItems) : videos;

  return (
    <div className="StudioDetailsPageSceneSiteSpecific">
      <MoviesScenesGrid
        data={slicedVideos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={title}
        viewAllLink={{ text: 'View All', url: getStudioDetailsRoute(studioId, studioName) }}
        hideNavigation
        hidePoster={hidePoster}
        refProp={containerRef}
      />
    </div>
  );
};

StudioDetailsPageSceneSiteSpecific.displayName = 'StudioDetailsPageSceneSiteSpecific';

export default StudioDetailsPageSceneSiteSpecific;
